import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CheckoutContext from "../contexts/CheckoutContext";



const PurchaseSuccess = () => {

    const navigate = useNavigate();
    const params = useParams();

    const { items, deliveryDate, deliveryDirection, clientData} = useContext(CheckoutContext);

    const [applyDiscount, setApplyDiscount] = useState(false);
    const [total, setTotal] = useState(null);
    const [totalWithDiscount, setTotalWithDiscount] = useState();

    useEffect(() => {
        //if total quantity is >= 4, then change applyDiscount to true
        let total_quantity = 0;
        for (let i = 0; i < items.length; i++) {
            total_quantity += items[i].quantity;
        }
        if (total_quantity >= process.env.REACT_APP_KILOS_NUMBER_DISCOUNT) {
            setApplyDiscount(true);
        } else {
            setApplyDiscount(false);
        }
        calculateTotalPrices();
    }, [items]);

    function calculateTotalPrices () {
        let total = 0;
        for (let i = 0; i < items.length; i++) {
            total += items[i].total;
        }
        setTotal(total);
        const MultipleToDiscountAsNumber = Number((1 - process.env.REACT_APP_KILOS_NUMBER_DISCOUNT_PERCENTAGE).toFixed(2));
        setTotalWithDiscount(total * MultipleToDiscountAsNumber);
    }

    return (
        <div className="mx-auto p-4 max-w-md sm:max-w-lg md:max-w-xl lg:max-w-2xl xl:max-w-3xl">
            <div className="bg-white shadow-md rounded-lg p-6">
                <div className="mb-8 text-center">
                    <h1 className="text-2xl font-bold text-green-600 mb-2">¡Gracias por tu compra, {clientData.name}!</h1>
                    <p className="text-gray-600">Detalle de tu pedido:</p>
                </div>

                <div className="mb-4">
                    {items.map((item) => (
                        <div key={item.product.id} className="flex items-center justify-between mb-2">
                            <p>
                                {item.quantity}Kg {item.product.name}
                            </p>
                            <p className="text-green-600">${(item.product.currentPrice * item.quantity).toLocaleString().replace(/,/g, '.')}</p>
                        </div>
                    ))}
                </div>

                <div className="mb-4">
                    <p>
                        Día de despacho: {deliveryDate[1]} {deliveryDate[2]} de {deliveryDate[3]}.
                    </p>
                    <p>
                        Dirección de entrega: {deliveryDirection.streetName} {deliveryDirection.streetNumber}{deliveryDirection.houseNumber ? ` (Casa ${deliveryDirection.houseNumber})` : ""}{deliveryDirection.houseNumber ? ` (Dpto ${deliveryDirection.departmentNumber})` : ""}, {deliveryDirection.commune}
                    </p>
                </div>

                <div className="border-t-2 border-gray-300 pt-4">
                    {applyDiscount ? (
                        <div>
                            <p className="text-lg font-semibold text-green-600 line-through">
                                Total: ${total?.toLocaleString().replace(/,/g, '.')}
                            </p>
                            <p className="text-lg font-semibold text-green-600">
                                Total Pagado: ${totalWithDiscount?.toLocaleString().replace(/,/g, '.')}
                            </p>
                        </div>
                    ) : (
                        <p className="text-lg font-semibold text-green-600">
                            Total Pagado: ${total?.toLocaleString().replace(/,/g, '.')}
                        </p>
                    )}
                </div>

                <div className="text-center mt-6">
                    <button   
                        className="bg-green-600 text-white rounded-full px-6 py-3 hover:bg-green-500 transition-all duration-300"
                        onClick={() => navigate("/")}
                    >
                        Volver al inicio
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PurchaseSuccess;