import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import CheckoutContext from "../contexts/CheckoutContext";
import ProductsResume from "../components/ProductsResume";
import DateResume from "../components/DateResume";
import ClientDataResume from "../components/ClientDataResume";
import axios from "axios";
import DeliveryForm from "../components/DeliveryForm";
import DeliveryMemoryForm from "../components/DeliveryMemoryForm";

function Delivery () {

    const selectDirectionRef = useRef(null);
    const deliveryFormRef = useRef(null);

    const navigate = useNavigate();
    const { items, deliveryDate, clientData, setDeliveryDirection } = useContext(CheckoutContext);

    const [previousPurchases, setPreviousPurchases] = useState([]);

    //use effect (nuevo, para recomendar datos en la direccion)    
    useEffect(() => {

        setDeliveryDirection({});

        async function searchAddress() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}purchase/${clientData.phoneNumber}`);
                setPreviousPurchases(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        searchAddress();
    }, []);




    useEffect(() => {
        if (items.length === 0 || deliveryDate.length === 0 || Object.keys(clientData).length === 0) {
            navigate("/");
        }
        if (previousPurchases.length > 0) {
            selectDirectionRef.current.scrollIntoView({ behavior: "smooth" });
        } else {
            deliveryFormRef.current.scrollIntoView({ behavior: "smooth" });
        }
    } ,[]);

    

    return(
        <div>
            <div className="mt-4 flex flex-col gap-2">
                <div>
                    <ProductsResume items={items}/>
                </div>
                <div>
                    <DateResume deliveryDate={deliveryDate}/>
                </div>
                <div>
                    <ClientDataResume clientData={clientData}/>
                </div>
            </div>
            <div className="mb-10 flex justify-center">
                {(previousPurchases.length > 0)  ? <div ref={selectDirectionRef}><DeliveryMemoryForm previousPurchases={previousPurchases}/></div> : <div ref={deliveryFormRef}><DeliveryForm/></div>}
            </div>
        </div>
    )
}

export default Delivery;