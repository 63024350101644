import React from "react";

const WaMeLink = () => {

    return (
        <div className="absolute bottom-[5px] left-1">
            {/* position absolute bottom right down */}
            <a href="https://wa.me/56998395682" target="_blank" rel="noopener noreferrer">
                <div className="flex items-center">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/479px-WhatsApp_icon.png" alt="WhatsApp" className="w-14 h-14" />
                    {/* <span>+56998395682</span> */}
                </div>
                
            </a>
        </div>
    );
}

export default WaMeLink;