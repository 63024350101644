import React, { useContext, useEffect, useState, useRef } from "react";
import CheckoutContext from "../contexts/CheckoutContext";
import { useNavigate } from "react-router-dom";
import { initMercadoPago } from "@mercadopago/sdk-react";
import ProductsResume from "../components/ProductsResume";
import DateResume from "../components/DateResume";
import ClientDataResume from "../components/ClientDataResume";
import DeliveryDirectionResume from "../components/DeliveryDirectionResume";
import CardPaymentWrapper from "../components/CardPaymentWrapper";
import LoadingSpinner from "../components/LoadingSpinner";
initMercadoPago(`${process.env.REACT_APP_MP_PUBLIC_KEY}`, {locale: 'es-CL'});

function CheckoutInfo () {

  const ref = useRef(null);

  const [renderPayment, setRenderPayment] = useState(false);
  const [initialization, setInitialization] = useState({});
  const [isPaymentRendered, setIsPaymentRendered] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { items, deliveryDate, deliveryDirection, clientData } = useContext(CheckoutContext);

  useEffect(() => {
    if (items.length === 0 || !clientData.name || !clientData.lastName || !clientData.phoneNumber || !clientData.email || deliveryDate.length !== 4 || Object.keys(deliveryDirection).length === 0 ) {
        navigate("/");
    }
  } ,[]);

  useEffect(() => {
    if (renderPayment) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  } ,[renderPayment]);

  useEffect(() => {
    if (isPaymentRendered) {
      setLoading(false);
    }
  } ,[isPaymentRendered]);

  const handleBuy = async () => {
      setLoading(true);
      //calculate if discount applies
      let applyDiscount = false;
      let total_quantity = 0;
      for (let i = 0; i < items.length; i++) {
          total_quantity += items[i].quantity;
      }
      if (total_quantity >= process.env.REACT_APP_KILOS_NUMBER_DISCOUNT) {
          applyDiscount = true;
      }
      const MultipleToDiscountAsNumber = Number((1 - process.env.REACT_APP_KILOS_NUMBER_DISCOUNT_PERCENTAGE).toFixed(2));
      //initialize payment
      setInitialization({
        amount: applyDiscount ? items.map((item) => item.total).reduce((a, b) => a + b, 0) * MultipleToDiscountAsNumber : items.map((item) => item.total).reduce((a, b) => a + b, 0),
        binary_mode: true,
        payer: {
          email: clientData.email,
        }
      })
      setRenderPayment(true); 
  };

  return(
      <div>
        <div className="mt-4 flex flex-col gap-2">
          <div>
            <DateResume deliveryDate={deliveryDate}/>
          </div>
          <div>
            <DeliveryDirectionResume deliveryDirection={deliveryDirection}/>
          </div>
          <div>
            <ClientDataResume clientData={clientData}/>
          </div>
          <div>
            <ProductsResume items={items}/>
          </div>
        </div>
        <div className="text-center mb-10 mt-4 text-white font-bold">
            <button className="bg-gray-700 px-12 hover:bg-gray-900 w-full h-10 disabled:bg-gray-300" disabled={isPaymentRendered} onClick={handleBuy}>IR A PAGAR</button>
        </div>
        <div ref={ref} className="pb-[420px]">
          {renderPayment ? (
              <div>
                  <CardPaymentWrapper initialization={initialization} setIsPaymentRendered={setIsPaymentRendered}/>
              </div>
          ) : null }
          {loading ? (
              <div className="text-center">
                <LoadingSpinner/>
              </div>
            ) : null }
        </div>
      </div>
  )
}

export default CheckoutInfo;