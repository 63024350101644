import React, { useState, useEffect, useContext, useRef } from "react";
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import CheckoutContext from "../contexts/CheckoutContext";
import ProductsResume from "../components/ProductsResume";
import DateResume from "../components/DateResume";
import axios from "axios";

const NAME_REGEX = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ_ ]+$/;
const PN_REGEX = /^[0-9]{9}$/;
const LN_REGEX = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ_ ]+$/;
const EMAIL_REGEX = /^[a-zA-Z0-9\.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/



function ClientDataForm () {

    const formRef = useRef(null);

    const navigate = useNavigate();

    const { items, deliveryDate, setClientData } = useContext(CheckoutContext);

    const [name, setName] = useState("");
    const [validName, setValidName] = useState(false);
    const [nameFocus, setNameFocus] = useState(false);

    const [lastName, setLastName] = useState("");
    const [validLastName, setValidLastName] = useState(false);
    const [lastNameFocus, setLastNameFocus] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState("");
    const [validPhoneNumber, setValidPhoneNumber] = useState(false);
    const [phoneNumberFocus, setPhoneNumberFocus] = useState(false);

    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [errMsg, setErrMsg] = useState("");

    //variable de estado default value email
    const [emailDefault, setEmailDefault] = useState("");

    useEffect(() => {
        if (items.length === 0 || deliveryDate.length !== 4) {
            navigate("/");
        }
        formRef.current.scrollIntoView({ behavior: 'smooth' });
    } ,[]);


    async function handleClickContinue (e) {
        e.preventDefault();
        // if button enabled with JS hack
        const v1 = NAME_REGEX.test(name);
        const v2 = LN_REGEX.test(lastName);
        const v3 = PN_REGEX.test(phoneNumber);
        const v4 = EMAIL_REGEX.test(email);
        if (!v1 || !v2 || !v3 || !v4) {
            setErrMsg("Invalid Entry");
            return;
        }

        const data = {
            name,
            lastName,
            phoneNumber,
            email
        };

        setClientData(data);
        //se guardo correctamente su correo para autocompletarse en proximas entregas
        alert("Sus datos de correo electrónico y direcciones de despacho se guardarán para agilizar futuras compras.");
        navigate("/delivery");
    };

    useEffect(() => {
        const result = NAME_REGEX.test(name);
        setValidName(result);
    }, [name]);

    useEffect(() => {
        const result = LN_REGEX.test(lastName);
        setValidLastName(result);
    }, [lastName]);

    useEffect(() => {
        const result = PN_REGEX.test(phoneNumber);
        setValidPhoneNumber(result);
    }, [phoneNumber]);

    useEffect(() => {
        const result = EMAIL_REGEX.test(email);
        setValidEmail(result);
    }, [email]);

    useEffect(() => {
        setErrMsg('');
    }, [name, lastName, phoneNumber, email]);


    //nuevo use effect para autocompletar el mail
    useEffect(() => {
        if (validPhoneNumber) {
            //revisamos en los purchases si el numero de telefono ya esta y sacamos el mail de este
            async function searchEmail() {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}purchase/${phoneNumber}/email`);
                    if (response.data.length > 0) {
                        setEmailDefault(response.data[0].buyerEmail);
                        setEmail(response.data[0].buyerEmail);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
            searchEmail();
        }
    }, [validPhoneNumber]);

    return(
        <div>
            <div className="mt-4 flex flex-col gap-2">
                <div>
                    <ProductsResume items={items}/>
                </div>
                <div>
                    <DateResume deliveryDate={deliveryDate}/>
                </div>
            </div>
            <div className="flex justify-center mb-4 pb-4">
                <div className="content-center px-8 py-6 text-left bg-white shadow-lg md:w-1/3 lg:w-1/3 sm:w-1/3 flex flex-col gap-4 mx-2 shadow-xl mt-4" ref={formRef}>
                    <p className={errMsg ? "bg-red-600 pl-4 rounded-lg" : "hidden"} aria-live="assertive">{errMsg}</p>
                    <p className="text-center text-3xl font-bold text-gray-600">Datos Personales</p>
                    <form onSubmit={handleClickContinue} className="flex flex-col justify-evenly grow pb-4">

                        <label htmlFor="name">
                            Nombre:
                            <span className={validName ? "ml-2 text-green-600" : "hidden"}>
                                <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <span className={validName || !name ? "hidden" : "ml-2 text-red-600"}>
                                <FontAwesomeIcon icon={faTimes} />
                            </span>
                        </label>
                        <input
                            className="bg-gray-300 rounded-lg pl-2 mb-2"
                            type="text"
                            id="name"
                            autoComplete="given-name"
                            onChange={(e) => setName(e.target.value)}
                            required
                            aria-invalid={validName ? "false" : "true"}
                            aria-describedby="cityName"
                            onFocus={() => setNameFocus(true)}
                            onBlur={() => setNameFocus(false)}
                        />
                        <p id="cityName" className={nameFocus && name &&
                            !validName ? "text-xs rounded-lg bg-red-300 p-1 relative" : "hidden"}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                                El nombre debe tener un formato válido.
                        </p>
                        
                        <label htmlFor="lastname">
                            Apellido:
                            <span className={validLastName ? "ml-2 text-green-600" : "hidden"}>
                                <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <span className={validLastName || !lastName ? "hidden" : "ml-2 text-red-600"}>
                                <FontAwesomeIcon icon={faTimes} />
                            </span>
                        </label>
                        <input
                            className="bg-gray-300 rounded-lg pl-2 mb-2"
                            type="text"
                            id="lastname"
                            autoComplete="family-name"
                            onChange={(e) => setLastName(e.target.value)}
                            required
                            aria-invalid={validLastName ? "false" : "true"}
                            aria-describedby="lastnameNote"
                            onFocus={() => setLastNameFocus(true)}
                            onBlur={() => setLastNameFocus(false)}
                        />
                        <p id="lastnameNote" className={lastNameFocus && lastName &&
                            !validLastName ? "text-xs rounded-lg bg-red-300 p-1 relative" : "hidden"}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                                El apellido debe tener un formato válido.
                        </p>
                        
                        <label htmlFor="phonenumber">
                            Número celular:
                            <span className={validPhoneNumber ? "ml-2 text-green-600" : "hidden"}>
                                <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <span className={validPhoneNumber || !phoneNumber ? "hidden" : "ml-2 text-red-600"}>
                                <FontAwesomeIcon icon={faTimes} />
                            </span>
                        </label>
                        <div className="flex w-full">
                            <p className="mr-1">+56</p>
                            <input
                                className="bg-gray-300 rounded-lg pl-2 mb-2 w-full"
                                type="tel"
                                id="number"
                                autoComplete="off"
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                required
                                aria-invalid={validPhoneNumber ? "false" : "true"}
                                aria-describedby="numbernote"
                                onFocus={() => setPhoneNumberFocus(true)}
                                onBlur={() => setPhoneNumberFocus(false)}
                            />
                        </div>
                        <p id="numbernote" className={phoneNumberFocus && phoneNumber &&
                            !validPhoneNumber ? "text-xs rounded-lg bg-red-300 p-1 relative" : "hidden"}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                                El número calular debe tener un formato válido.
                        </p>

                        <label htmlFor="email">
                            Correo electrónico:
                            <span className={validEmail ? "ml-2 text-green-600" : "hidden"}>
                                <FontAwesomeIcon icon={faCheck} />
                            </span>
                            <span className={validEmail || !email ? "hidden" : "ml-2 text-red-600"}>
                                <FontAwesomeIcon icon={faTimes} />
                            </span>
                        </label>
                        <input
                            className="bg-gray-300 rounded-lg pl-2 mb-2"
                            type="text"
                            id="email"
                            autoComplete="email"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            aria-invalid={validEmail ? "false" : "true"}
                            aria-describedby="emailnote"
                            defaultValue={emailDefault}
                            onFocus={() => setEmailFocus(true)}
                            onBlur={() => setEmailFocus(false)}
                        />
                        <p id="emailnote" className={emailFocus && email &&
                            !validEmail ? "text-xs rounded-lg bg-red-300 p-1 relative" : "hidden"}>
                                <FontAwesomeIcon icon={faInfoCircle} />
                                El correo electrónico debe tener un formato válido.
                        </p>

                        <div className="text-center text-white font-bold mt-5">
                            <input type="submit" disabled={ !validName || !validLastName || !validPhoneNumber || !validEmail } className="bg-gray-700 hover:bg-gray-900 w-full h-10 disabled:bg-gray-300" value="CONTINUAR" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ClientDataForm;