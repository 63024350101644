import React from "react";

function DeliveryDateButton (props) {
    return(
        <div>
            <div>
                <div id={props.instance.id}>
                    <p className="text-center">{props.instance.dayName}</p>
                </div>
                <div>
                    <p className="text-center">{props.instance.dayNumber}</p>
                </div>
                <div>
                    <p className="text-center">{props.instance.monthName}</p>
                </div>
            </div>
        </div>
    );
}

export default DeliveryDateButton;