import React, { useState } from "react";


function DateResume (props) {

    return(
        <div className="bg-slate-50 py-5">
            <div className="font-bold text-2xl px-4 text-gray-600 mb-3 text-center">
                <p>Día del Despacho</p>
            </div>
            <div className="text-center text-gray-500">
                <p>{props.deliveryDate[1]} {props.deliveryDate[2]} de {props.deliveryDate[3]}</p>
            </div>
        </div>
    )
}

export default DateResume;