import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";


function Cart(props) {

    const navigate = useNavigate();
    const [applyDiscount, setApplyDiscount] = useState(false);
    const [total, setTotal] = useState(null);
    const [totalWithDiscount, setTotalWithDiscount] = useState();

    useEffect(() => {
        //if total quantity is >= 4, then change applyDiscount to true
        let total_quantity = 0;
        for (let i = 0; i < props.cart.length; i++) {
            total_quantity += props.cart[i].quantity;
        }
        if (total_quantity == 1 && !props.discountAlertRendered) {
            //promocion si llevas 4 o mas kilos
            alert("¡Promoción si llevas 4 o más kilos!");
            props.setDiscountAlertRendered(true);
        }   

        if (total_quantity >= process.env.REACT_APP_KILOS_NUMBER_DISCOUNT) {
            setApplyDiscount(true);
        } else {
            setApplyDiscount(false);
        }
        calculateTotalPrices();
    }, [props.cart]);

    function calculateTotalPrices () {
        let total = 0;
        for (let i = 0; i < props.cart.length; i++) {
            total += props.cart[i].total;
        }
        setTotal(total);
        const MultipleToDiscountAsNumber = Number((1 - process.env.REACT_APP_KILOS_NUMBER_DISCOUNT_PERCENTAGE).toFixed(2));
        setTotalWithDiscount(total * MultipleToDiscountAsNumber);
    }

    function handleClickContinue (event) {
        event.preventDefault();
        let total_quantity = 0;;
        for (let i = 0; i < props.cart.length; i++) {
            total_quantity += props.cart[i].quantity;
        }
        if (total_quantity < 2) {
            alert("Compra minima de 2kg, selecciona mas productos");
            return;
        }
        navigate("/date");
    }

    return (
        <div>
            <div className="rounded-t-xl m-2 text-gray-600">
                <p className="font-bold text-xl">Tu compra</p>
                { props.cart.length > 0 ? (
                <div>
                    <div>
                        {props.cart.map((item) =>
                            <p key={item.product.id}>{item.quantity}Kg {item.product.name}, Subtotal: ${item.total.toLocaleString().replace(/,/g, '.')}</p>
                        )}
                    </div>
                    { applyDiscount ? (
                    <>
                    <div>
                        <p className="font-bold text-lg line-through">Total a Pagar: ${total.toLocaleString().replace(/,/g, '.')}</p>
                    </div>
                    <div>
                        <p className="font-bold text-lg">Total a Pagar: ${totalWithDiscount.toLocaleString().replace(/,/g, '.')}</p>
                    </div>
                    </>

                    ) : (
                    <div>
                        <p className="font-bold text-lg">Total a Pagar: ${total.toLocaleString().replace(/,/g, '.')}</p>
                    </div>
                    )}
                </div>) : (
                    <div>
                        <p className="font-bold text-xl">No hay productos seleccionados</p>
                    </div>
                )}
            </div>
            { props.productSelected !== null ? (
                <div className="h-10 mx-8 text-white font-bold">
                    <button onClick={() => props.setProductSelected(null)} className="bg-gray-700 w-full hover:bg-gray-900 disabled:bg-gray-300 h-full">Actualizar Carrito</button>
                </div>
                ) : (
            <div className="h-10 mx-8 text-white font-bold">
                    <button onClick={handleClickContinue} className={"w-full h-full" + (props.disabled ? " bg-gray-300" : " bg-gray-700 hover:bg-gray-900")}>CONTINUAR</button>
            </div>
            )}
        </div>
    );
}

export default Cart;