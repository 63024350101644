import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DayForm from './views/DayForm';
import { CheckoutProvider } from './contexts/CheckoutContext';
import ProductForm from './views/ProductForm';
import Delivery from './views/Delivery';
import CheckoutInfo from './views/CheckoutInfo';
import PaymentSuccess from './views/PaymentSuccess';
import ClientDataForm from './views/ClientDataForm';
import PurchaseSuccess from './views/PurchaseSuccess';
import PaymentFailure from './views/PaymentFailure';
import PaymentInProcess from './views/PaymentInProcess';
import PurchaseInProcess from './views/PurchaseInProcess';


function App() {
  return (
    <CheckoutProvider>
      <BrowserRouter>
          <div className=''>
            <Routes>
              <Route path="/delivery" element={<Delivery />} />
              <Route path="/client" element={<ClientDataForm />} />
              <Route path="/" element={<ProductForm />} />
              <Route path="/date" element={<DayForm />} />
              <Route path="/checkout/info" element={<CheckoutInfo />} />
              <Route path="/payment/success/:payment_id" element={<PaymentSuccess />} />
              <Route path="/purchase/success/:payment_id" element={<PurchaseSuccess />} />
              <Route path="/payment/failure/:payment_id" element={<PaymentFailure />} />
              <Route path="/payment/inprocess/:payment_id" element={<PaymentInProcess />} />
              <Route path="/purchase/inprocess/:payment_id" element={<PurchaseInProcess />} />
            </Routes>
          </div>
      </BrowserRouter>
    </CheckoutProvider>
  );
}

export default App;