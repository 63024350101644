import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CheckoutContext from "../contexts/CheckoutContext";
import StatusScreenWrapper from "../components/StatusScreenWrapper";
import WaMeLink from "../components/WaMeLink";

const PaymentFailure = () => {

    const navigate = useNavigate();
    const params = useParams();

    const { clientData } = useContext(CheckoutContext);
    const [renderResult, setRenderResult] = useState(false);

    return (
        <div>
            <div>
                <StatusScreenWrapper payment_id={params.payment_id} setRenderResult={setRenderResult}/>
            </div>
            <div className="bg-red-550 text-center mx-2 mt-2 rounded-xl">
                <p>{clientData.name}, su pedido no será despachado, el pago fue rechazado, </p>
                <p>Por favor Intentelo nuevamente</p>
            </div>
            <div className="text-center mx-2 h-8 mt-2">
                <button className="bg-slate-600 text-white rounded w-full h-full" onClick={() => navigate("/checkout/info")}>Volver a Intentar</button>
            </div>
            <WaMeLink />
        </div>
    );
}

export default PaymentFailure;