import React, { useState } from "react";


function DeliveryDirectionResume (props) {

    return(
        <div className="bg-slate-50 py-5">
            <div className="font-bold text-2xl px-4 text-gray-600 mb-3 text-center">
                <p>Dirección de Despacho</p>
            </div>
            <div className="text-center text-gray-500">
                <p>
                    {props.deliveryDirection.streetName} {props.deliveryDirection.streetNumber}
                    { (props.deliveryDirection.houseNumber.length > 0) ? ` (Casa: ${props.deliveryDirection.houseNumber}), ` : null}
                    { (props.deliveryDirection.departmentNumber.length > 0) ? ` (Dpto: ${props.deliveryDirection.departmentNumber}), ` : null}
                    { (!(props.deliveryDirection.departmentNumber.length > 0) && !(props.deliveryDirection.houseNumber.length > 0)) ? `, ` : null}
                    {props.deliveryDirection.commune}
                
                </p>
            </div>
        </div>
    )
}

export default DeliveryDirectionResume;