import React, { useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StatusScreenWrapper from "../components/StatusScreenWrapper";



const PaymentSuccess = () => {

    const navigate = useNavigate();
    const params = useParams();

    const [renderResult, setRenderResult] = useState(false);

    return (
        <div>
            <StatusScreenWrapper payment_id={params.payment_id} setRenderResult={setRenderResult}/>
            {renderResult ? (<div className="text-center mx-2 h-10 mt-2">
                <button className="bg-green-600 text-white rounded-b-xl w-full h-full" onClick={() => navigate(`/purchase/success/${params.payment_id}`)}>Continuar</button>
            </div>) : null }
        </div>
    );
}

export default PaymentSuccess;