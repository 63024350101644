import React, { useState, useEffect, useContext } from "react";
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import CheckoutContext from "../contexts/CheckoutContext";

const NAME_REGEX = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ_ ]{3,}$/;
const NUMBER_REGEX = /^[0-9]{1,}$/;
const COMMUNE_REGEX = /^(Las Condes|Lo Barnechea|Vitacura)$/;
const CITY_REGEX = /^[a-zA-Z]{3,}$/;
const DN_REGEX = /^[0-9a-zA-Z_ ]{0,}$/;
const HN_REGEX = /^[0-9a-zA-Z_ ]{0,}$/;


function DeliveryForm (props) {
    const navigate = useNavigate();
    const { setDeliveryDirection, items, deliveryDate, clientData } = useContext(CheckoutContext);

    const [name, setName] = useState("");
    const [validName, setValidName] = useState(false);
    const [nameFocus, setNameFocus] = useState(false);

    const [number, setNumber] = useState("");
    const [validNumber, setValidNumber] = useState(false);
    const [numberFocus, setNumberFocus] = useState(false);

    const [commune, setCommune] = useState("");
    const [validCommune, setValidCommune] = useState(false);
    const [communeFocus, setCommuneFocus] = useState(false);

    const [city, setCity] = useState("Santiago");
    const [validCity, setValidCity] = useState(true);
    const [cityFocus, setCityFocus] = useState(false);

    const [departmentNumber, setDepartmentNumber] = useState("");
    const [validDepartmentNumber, setValidDepartmentNumber] = useState(false);
    const [departmentNumberFocus, setDepartmentNumberFocus] = useState(false);

    const [houseNumber, setHouseNumber] = useState("");
    const [validHouseNumber, setValidHouseNumber] = useState(false);
    const [houseNumberFocus, setHouseNumberFocus] = useState(false);

    const [errMsg, setErrMsg] = useState("");

    async function handleClickContinue (e) {
        e.preventDefault();
        // if button enabled with JS hack
        const v1 = NAME_REGEX.test(name);
        const v2 = NUMBER_REGEX.test(number);
        const v3 = COMMUNE_REGEX.test(commune);
        const v4 = CITY_REGEX.test(city);
        const v5 = DN_REGEX.test(departmentNumber);
        const v6 = HN_REGEX.test(houseNumber);
        if (!v1 || !v2 || !v3 || !v4 || !v5 || !v6) {
            setErrMsg("Invalid Entry");
            return;
        }

        const data = {
            streetName: name,
            streetNumber: number,
            commune,
            city,
            departmentNumber,
            houseNumber
        };

        setDeliveryDirection(data);
        navigate("/checkout/info");
    };

    useEffect(() => {
        const result = NAME_REGEX.test(name);
        setValidName(result);
    }, [name]);

    useEffect(() => {
        const result = NUMBER_REGEX.test(number);
        setValidNumber(result);
    }, [number]);

    useEffect(() => {
        const result = COMMUNE_REGEX.test(commune);
        setValidCommune(result);
    }, [commune]);

    useEffect(() => {
        // const result = CITY_REGEX.test(city);
        setValidCity(true);
    }, [city]);

    useEffect(() => {
        const result = DN_REGEX.test(departmentNumber);
        setValidDepartmentNumber(result);
    }, [departmentNumber]);

    useEffect(() => {
        const result = HN_REGEX.test(houseNumber);
        setValidHouseNumber(result);
    }, [houseNumber]);

    useEffect(() => {
        setErrMsg('');
    }, [name, number, commune, city, departmentNumber, houseNumber]);

    return(
        <div className="content-center px-8 py-6 text-left bg-white shadow-xl flex flex-col gap-4 mt-4 mx-2">
            <p className={errMsg ? "bg-red-600 pl-4 rounded-lg" : "hidden"} aria-live="assertive">{errMsg}</p>
            <p className="text-center text-3xl font-bold text-gray-600">Dirección de Despacho</p>
            <form onSubmit={handleClickContinue} className="flex flex-col justify-evenly grow pb-4">

                <label htmlFor="city">
                    Ciudad:
                    <span className={validCity ? "ml-2 text-green-600" : "hidden"}>
                        <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validCity || !city ? "hidden" : "ml-2 text-red-600"}>
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                </label>
                <input
                    className="bg-gray-300 rounded-lg pl-2 mb-2"
                    type="text"
                    id="city"
                    autoComplete="off"
                    onChange={(e) => setCity(e.target.value)}
                    value="Santiago"
                    enabled="flase"
                    required
                    aria-invalid={validCity ? "false" : "true"}
                    aria-describedby="cityNote"
                    onFocus={() => setCityFocus(true)}
                    onBlur={() => setCityFocus(false)}
                />
                <p id="cityNote" className={cityFocus && city &&
                    !validCity ? "text-xs rounded-lg bg-red-300 p-1 relative" : "hidden"}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        La ciudad debe tener un formato válido.
                </p>
                
                <label htmlFor="commune">
                    Comuna:
                    <span className={validCommune ? "ml-2 text-green-600" : "hidden"}>
                        <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validCommune || !commune ? "hidden" : "ml-2 text-red-600"}>
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                </label>
                <select
                    className="bg-gray-300 rounded-lg pl-2 mb-2"
                    type="text"
                    id="commune"
                    autoComplete="off"
                    onChange={(e) => setCommune(e.target.value)}
                    required
                    aria-invalid={validCommune ? "false" : "true"}
                    aria-describedby="communeNote"
                    >
                    <option value="selecciona">seleccionar</option>
                    <option value="Las Condes">Las Condes</option>
                    <option value="Lo Barnechea">Lo Barnechea</option>
                    <option value="Vitacura">Vitacura</option>
                </select>
                <p id="communeNote" className={communeFocus && commune &&
                    !validCommune ? "text-xs rounded-lg bg-red-300 p-1 relative" : "hidden"}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        La comuna debe tener un formato válido.
                </p>
                
                <label htmlFor="name">
                    Calle:
                    <span className={validName ? "ml-2 text-green-600" : "hidden"}>
                        <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validName || !name ? "hidden" : "ml-2 text-red-600"}>
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                </label>
                <input
                    className="bg-gray-300 rounded-lg pl-2 mb-2"
                    type="text"
                    id="name"
                    autoComplete="off"
                    onChange={(e) => setName(e.target.value)}
                    required
                    aria-invalid={validName ? "false" : "true"}
                    aria-describedby="namenote"
                    onFocus={() => setNameFocus(true)}
                    onBlur={() => setNameFocus(false)}
                />
                <p id="namenote" className={nameFocus && name &&
                    !validName ? "text-xs rounded-lg bg-red-300 p-1 relative" : "hidden"}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        El nombre debe tener un formato válido.
                </p>

                
                <label htmlFor="number">
                    Número:
                    <span className={validNumber ? "ml-2 text-green-600" : "hidden"}>
                        <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validNumber || !number ? "hidden" : "ml-2 text-red-600"}>
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                </label>
                <input
                    className="bg-gray-300 rounded-lg pl-2 mb-2"
                    type="text"
                    id="number"
                    autoComplete="off"
                    onChange={(e) => setNumber(e.target.value)}
                    required
                    aria-invalid={validNumber ? "false" : "true"}
                    aria-describedby="numbernote"
                    onFocus={() => setNumberFocus(true)}
                    onBlur={() => setNumberFocus(false)}
                />
                <p id="numbernote" className={numberFocus && number &&
                    !validNumber ? "text-xs rounded-lg bg-red-300 p-1 relative" : "hidden"}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        El número debe tener un formato válido.
                </p>


                <label htmlFor="departmentNumber">
                    Número de departamento (contestar solo en caso de edificio):
                    <span className={validDepartmentNumber && departmentNumber.length > 0  ? "ml-2 text-green-600" : "hidden"}>
                        <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validDepartmentNumber || !departmentNumber ? "hidden" : "ml-2 text-red-600"}>
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                </label>
                <input
                    className="bg-gray-300 rounded-lg pl-2 mb-2 w-full"
                    type="text"
                    id="departmentNumber"
                    autoComplete="off"
                    onChange={(e) => setDepartmentNumber(e.target.value)}
                    aria-invalid={validDepartmentNumber ? "false" : "true"}
                    aria-describedby="departmentNumberNote"
                    onFocus={() => setDepartmentNumberFocus(true)}
                    onBlur={() => setDepartmentNumberFocus(false)}
                />
                <p id="departmentNumberNote" className={departmentNumberFocus && departmentNumber &&
                    !validDepartmentNumber ? "text-xs rounded-lg bg-red-300 p-1 relative" : "hidden"}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        El número de departamento debe tener un formato válido.
                </p>

                
                <label htmlFor="houseNumber">
                    Número de casa (contestar solo en caso de condominio):
                    <span className={validHouseNumber && houseNumber > 0 ? "ml-2 text-green-600" : "hidden"}>
                        <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validHouseNumber || !houseNumber ? "hidden" : "ml-2 text-red-600"}>
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                </label>
                <input
                    className="bg-gray-300 rounded-lg pl-2 mb-2 w-full"
                    type="text"
                    id="houseNumber"
                    autoComplete="off"
                    onChange={(e) => setHouseNumber(e.target.value)}
                    aria-invalid={validHouseNumber ? "false" : "true"}
                    aria-describedby="houseNumberNote"
                    onFocus={() => setHouseNumberFocus(true)}
                    onBlur={() => setHouseNumberFocus(false)}
                />
                <p id="houseNumberNote" className={houseNumberFocus && houseNumber &&
                    !validHouseNumber ? "text-xs rounded-lg bg-red-300 p-1 relative" : "hidden"}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        El número de casa debe tener un formato válido.
                </p>


                <div className="text-center text-white font-bold mt-5">
                    <input type="submit" disabled={!(validName && validNumber && validCommune && validCity && validDepartmentNumber && validHouseNumber)} className="bg-gray-700 hover:bg-gray-900 w-full h-10 disabled:bg-gray-300" value="CONTINUAR" />
                </div>
            </form>
        </div>
    )
}

export default DeliveryForm;