import React, { useContext, useEffect, useState, useMemo, useCallback } from "react";
import CheckoutContext from "../contexts/CheckoutContext";
import { useNavigate } from "react-router-dom";
import { initMercadoPago, CardPayment } from "@mercadopago/sdk-react";
import axios from "axios";
initMercadoPago(`${process.env.REACT_APP_MP_PUBLIC_KEY}`, {locale: 'es-CL'});

async function createPurchase(deliveryDate, clientData, deliveryDirection, items, params, purchaseStatus) {
  try {
      const paymentId = params.id.toString();
      //count the sum of item quantities
      let total_quantity = 0;
      for (let i = 0; i < items.length; i++) {
          total_quantity += items[i].quantity;
      }
      //if there are more than 4 items, then apply discount
      let applyDiscount = false;
      if (total_quantity >= process.env.REACT_APP_KILOS_NUMBER_DISCOUNT) {
          applyDiscount = true;
      }
      //calculate the total amount
      const MultipleToDiscountAsNumber = Number((1 - process.env.REACT_APP_KILOS_NUMBER_DISCOUNT_PERCENTAGE).toFixed(2));
      //get the final price
      const totalAmount = applyDiscount ? items.map((item) => item.total).reduce((a, b) => a + b, 0) * MultipleToDiscountAsNumber : items.map((item) => item.total).reduce((a, b) => a + b, 0);
      const totalPrevAmount = items.map((item) => item.total).reduce((a, b) => a + b, 0);

      const data = {
          instanceId: deliveryDate[0],
          paymentId: paymentId,
          buyerName: clientData.name,
          buyerLastName: clientData.lastName,
          buyerEmail: clientData.email,
          buyerPhoneNumber: clientData.phoneNumber,
          streetName: deliveryDirection.streetName,
          streetNumber: deliveryDirection.streetNumber,
          commune: deliveryDirection.commune,
          city: deliveryDirection.city,
          departmentNumber: deliveryDirection.departmentNumber,
          houseNumber: deliveryDirection.houseNumber,
          totalAmount: totalAmount,
          purchaseStatus: purchaseStatus,
          purchaseDate: null,
          paymentType: null,
          collectionId: null,
          collectionStatus: null,
          preferenceId: null,
          paymentStatus: params.status,
          paymentStatusDetail: params.status_detail,
          merchantOrderId: null,
          items: items,
          totalPrevAmount: totalPrevAmount,
      };

      const response = await axios.post(`${process.env.REACT_APP_API_URL}purchase`, data);
      console.log("Created Order:", response);
  } catch (error) {
      console.log(error);
  }
}

function CardPaymentWrapper (props) {
  const customization = {
    visual: {
      style: {
      }
    },
    paymentMethods: {
      maxInstallments: 1,
    },
    binary_mode: true,
  }

  const navigate = useNavigate();

  const { items, deliveryDate, deliveryDirection, clientData } = useContext(CheckoutContext);
       
  const onSubmit = async (formData) => {
  // callback llamado al hacer clic en el botón enviar datos
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}payment/process`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        // recibir el resultado del pago
        if (response.status == "approved") {
          // // manejar la respuesta de pago aprobado
          // navigate(`/payment/success/${response.status}/${response.status_detail}/${response.id}`);
          async function approve() {
            try {
              await createPurchase(deliveryDate, clientData, deliveryDirection, items, response, "accepted");
              navigate(`/payment/success/${response.id}`);
            } catch (error) {
              console.log(error);
            }
          }
          approve();
        }
        else if (response.status == "rejected") {
          async function reject() {
            try {
              await createPurchase(deliveryDate, clientData, deliveryDirection, items, response, "rejected");
              navigate(`/payment/failure/${response.id}`);
            } catch (error) {
              console.log(error);
            }
          }
          reject();
        } else if (response.status == "in_process") {
          async function inprocess() {
            try {
              await createPurchase(deliveryDate, clientData, deliveryDirection, items, response, "rejected");
              navigate(`/payment/inprocess/${response.id}`);
            } catch (error) {
              console.log(error);
            }
          }
          inprocess();
        }
        resolve();
      })
      .catch((error) => {
        // manejar la respuesta de error al intentar crear el pago
        console.log("ERROR AL MANDAR EL PAGO: ", error);
        reject();
      });
    });
  };
          
  const onError = async (error) => {
    console.log("ONERROR: ", error);
  // callback llamado para todos los casos de error de Brick
  console.log(error);
  };
  
  
  const onReady = async () => {
    console.log("ONREADY");
    props.setIsPaymentRendered(true);
  };

    // Memoize the component using useMemo
    const memoizedComponent = useMemo(
        () => (
            <div>
            <div className="font-bold text-gray-800 pl-4">
                <p>
                  Monto a Pagar: ${props.initialization.amount.toLocaleString().replace(/,/g, '.')}
                </p>
            </div>
            <div>
                <CardPayment
                initialization={props.initialization}
                customization={customization}
                onSubmit={onSubmit}
                onReady={onReady}
                onError={onError}
                />
            </div>
            </div>
        ),
        [props.initialization] // Add dependencies here
        );

        return memoizedComponent;
}

export default CardPaymentWrapper;