import { createContext, useEffect, useState } from "react";

const CheckoutContext = createContext();

export function CheckoutProvider({ children }) {
    const prevItems = JSON.parse(localStorage.getItem("items"));
    const prevDeliveryDate = JSON.parse(localStorage.getItem("deliveryDate"));
    const prevDeliveryDirection = JSON.parse(localStorage.getItem("deliveryDirection"));
    const prevClientData = JSON.parse(localStorage.getItem("clientData"));

    const [items, setItems] = useState(prevItems || []);
    const [deliveryDate, setDeliveryDate] = useState(prevDeliveryDate || []);
    const [deliveryDirection, setDeliveryDirection] = useState(prevDeliveryDirection || {});
    const [clientData, setClientData] = useState(prevClientData || {});

    if (prevItems) {
        localStorage.setItem("items", JSON.stringify(prevItems));
    }
    if (prevDeliveryDate) {
        localStorage.setItem("deliveryDate", JSON.stringify(prevDeliveryDate));
    }
    if (prevDeliveryDirection) {
        localStorage.setItem("deliveryDirection", JSON.stringify(prevDeliveryDirection));
    }
    if (prevClientData) {
        localStorage.setItem("clientData", JSON.stringify(prevClientData));
    }

    useEffect(() => {
        localStorage.setItem("items", JSON.stringify(items));
    }, [items]);

    useEffect(() => {
        localStorage.setItem("deliveryDate", JSON.stringify(deliveryDate));
    }, [deliveryDate]);

    useEffect(() => {
        localStorage.setItem("deliveryDirection", JSON.stringify(deliveryDirection));
    }, [deliveryDirection]);

    useEffect(() => {
        localStorage.setItem("clientData", JSON.stringify(clientData));
    }, [clientData]);

    return (
        <CheckoutContext.Provider value={{ items, setItems, setDeliveryDate, deliveryDate, deliveryDirection, setDeliveryDirection, clientData, setClientData }}>
            {children}
        </CheckoutContext.Provider>
    );
}


export default CheckoutContext;