import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import CheckoutContext from "../contexts/CheckoutContext";
import { StatusScreen } from "@mercadopago/sdk-react";



const StatusScreenWrapper = (props) => {

    const initialization = {
        paymentId: props.payment_id,
    };

    async function onError(error) {
        console.log(error);
    }

    async function onReady() {
        //que no se corra dos veces con el rerender
        console.log("onReady");
        props.setRenderResult(true);
    }

    const memoizedComponent = useMemo(
        () => (
            <div className="mt-2 mx-2">
                <StatusScreen
                    initialization={initialization}
                    onReady={onReady}
                    onError={onError}
                />  
            </div>
        ),
    [props.payment_id]
    );

    return memoizedComponent;
}

export default StatusScreenWrapper;