import React from "react";

const MainTitle = () => {

    return (
        <div className="sticky top-0 py-4 bg-[url('parron_o.jpeg')] bg-cover my-4">
            <div className="text-center text-red-900">
                <span className="font-bold text-[44px] tracking-tight">UVAS PREMIUM</span>
            </div>
        </div>
    );
}

export default MainTitle;