import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ProductInput from "../components/ProductInput";
import CheckoutContext from "../contexts/CheckoutContext";
import ProductShow from "../components/ProductShow";
import Cart from "../components/Cart";
import MainTitle from "../components/MainTitle";
import LoadingSpinner from "../components/LoadingSpinner";
import WaMeLink from "../components/WaMeLink";

function ProductForm () {

    const navigate = useNavigate();

    const [products, setProducts] = useState([]);
    const [cart, setCart] = useState([]);
    //to wait for render all the components
    const [isLoaded, setIsLoaded] = useState(false);
    const [productSelected, setProductSelected] = useState(null);
    //to know when to block continue button
    const [disabled, setDisabled] = useState(true);

    const [discountAlertRendered, setDiscountAlertRendered] = useState(false);

    const { items, setItems, setDeliveryDate, setDeliveryDirection, setClientData } = useContext(CheckoutContext);

    useEffect(() => {
        async function getProducts() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}product/active`);
                setProducts(response.data);
                setIsLoaded(true)
            } catch (error) {
                console.log(error);
            }
        }
        getProducts();
    }, []);

    useEffect(() => {
        setItems([]);
        setDeliveryDate([]);
        setDeliveryDirection({});
        setClientData({});
    } ,[]);

    useEffect(() => {
        //if total quantity is >= 2, then enable continue button
        let total_quantity = 0;
        for (let i = 0; i < cart.length; i++) {
            total_quantity += cart[i].quantity;
        }
        if (total_quantity >= 2) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [cart]);

    

    if (isLoaded) {
        return(
            <>

                { productSelected === null ? (     
                    <MainTitle />
                ) : (
                    null
                )}
                <div className="text-center mt-2 pb-4">
                    { productSelected === null ? (
                        <div>
                            <div className="text-center text-2xl text-gray-600 mb-6 font-bold">
                                <p>Selecciona tus Productos</p>
                            </div>
                            <div className="flex justify-evenly gap-x-1.5 mb-6">
                                {products.map((product) => 
                                    <ProductInput setProductSelected={setProductSelected} product={product} key={product.id}/>
                                )}  
                            </div>
                        </div>
                    ) : (  
                        <div className="mt-20 mb-6">
                            <ProductShow product={productSelected} setProductSelected={setProductSelected} cart={cart} setCart={setCart} setItems={setItems} key={productSelected.id} items={items}/>
                        </div>
                    )}
                    <Cart cart={cart} productSelected={productSelected} setProductSelected={setProductSelected} disabled={disabled} setDisabled={disabled} discountAlertRendered={discountAlertRendered} setDiscountAlertRendered={setDiscountAlertRendered}/>
                </div>
                { productSelected === null ? (
                    <WaMeLink />
                ) : (
                    null
                )}
            </>
        )
    } else {
        return(
            <div className="mt-72">
                <LoadingSpinner/>
            </div>
        );
    }
}

export default ProductForm;