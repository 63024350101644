import React from "react";


function ProductInput (props) {

    return(
        <div onClick={() => props.setProductSelected(props.product)} id={props.product.id} className="border-2 border-gray-200 shadow-xl max-w-[35%] overflow-hidden py-4 px-2">
        
            <div className="rounded-t-xl">
                <div className="text-2xl text-center font-bold text-gray-600">
                    <p className="">{props.product.name}</p>
                </div>
                <div className="text-xs pb-2 text-gray-600">
                    <p>Precio: ${props.product.currentPrice}/kg</p>
                </div>
                <div>
                    <img className="w-full h-40 object-contain bg-white" src={props.product.imageUrl} alt={props.product.name} />
                </div>
            </div>           
        </div>
    )
}

export default ProductInput;