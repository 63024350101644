import React, { useState } from "react";


function ClientDataResume(props) {

    return (
        <div className="bg-slate-50 py-5">
            <div className="font-bold text-2xl px-4 text-gray-600 mb-3 text-center">
                <p>Datos Personales</p>
            </div>
            <table className="mx-auto">
                <tbody>
                    <tr>
                        <th className="text-gray-600 text-right">NOMBRE: </th>
                        <td className="text-gray-500 text-left">&nbsp; {props.clientData.name} {props.clientData.lastName}</td>
                    </tr>
                    <tr>
                        <th className="text-gray-600 text-right">CELULAR: </th>
                        <td className="text-gray-500 text-left">&nbsp; +56{props.clientData.phoneNumber}</td>
                    </tr>
                    <tr>
                        <th className="text-gray-600 text-right">EMAIL: </th>
                        <td className="text-gray-500 text-left">&nbsp; {props.clientData.email}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default ClientDataResume;