import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DeliveryDateButton from "../components/DeliveryDateButton";
import CheckoutContext from "../contexts/CheckoutContext";
import ProductsResume from "../components/ProductsResume";
import LoadingSpinner from "../components/LoadingSpinner";




const DayForm = () => {
    const navigate = useNavigate();


    const [instances, setInstances] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [dayClicked, setDayClicked] = useState(null);

    const { items, setDeliveryDate, deliveryDate } = useContext(CheckoutContext);

    useEffect(() => {
        if (items.length === 0) {
            navigate("/");
        } else {
            setDayClicked(deliveryDate[0]);
        }
    } ,[]);

    useEffect(() => {
        async function getInstances() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}instance/active`);
                setInstances(response.data);
                setIsLoaded(true)
            } catch (error) {
                console.log(error);
            }
        }
        getInstances();
    }, []);

    function handleClickInstance (event) {
        event.preventDefault();
        setDeliveryDate(
            [
                event.currentTarget.id,
                event.currentTarget.attributes.dayname.value,
                event.currentTarget.attributes.daynumber.value,
                event.currentTarget.attributes.monthname.value
            ]
        );
        setDayClicked(event.currentTarget.id);
    }

    function handleClickContinue (event) {
        event.preventDefault();
        navigate(`/client`);
    }


    if (isLoaded) {
        return (
            <div>
                <div className="mt-4">
                    <div>
                        <ProductsResume items={items}/>
                    </div>
                </div>
                <div className="mt-4">

                </div>
                <div className="text-center text-2xl text-gray-600 mb-6 font-bold">
                    <p>Selecciona día de despacho</p>
                </div>

                <div className="flex justify-center justify-around font-bold text-gray-600">
                    {instances.map((instance) => 
                        <div onClick={handleClickInstance} id={instance.id} key={instance.id} dayname={instance.dayName} daynumber={instance.dayNumber} monthname={instance.monthName}
                            className={"border-2 border-gray-200 shadow-xl m-2" + (dayClicked == instance.id ? " bg-blue-400" : "")} style={{width: "100px"}}>
                            <DeliveryDateButton instance={instance} />
                        </div>
                    )}
                </div>
                <div className="h-10 mx-8 text-white font-bold mt-10">
                    <button onClick={handleClickContinue} className="bg-gray-700 hover:bg-gray-900 disabled:bg-gray-300 h-full w-full" disabled={deliveryDate.length === 0}>Continuar</button>
                </div>
            </div>
        );
    } else {
        return(
            <div className="mt-72">
                <LoadingSpinner/>
            </div>
        );
    }
    
}

export default DayForm;
